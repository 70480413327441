.datepicker-input {
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 18px;
    width: fit-content;
}

.vc-container {
    background: #f5f3ee !important;
}

.vc-container .vc-title {
    text-transform: uppercase;
    font-size: 14px !important;
    color: #0f393b !important;
    opacity: 0.5 !important;
}

.vc-container .vc-day .vc-highlights .vc-highlight {
    background-color: #e5e8e9 !important;
}

.vc-container .vc-day .vc-highlights+span {
    color: #0f393b !important;
}

.vc-container .vc-day .vc-highlights+span:hover,
.vc-container .vc-day .vc-highlights+span:focus {
    background-color: transparent !important;
}

.vc-container .vc-day+span {
    color: #0f393b;
}

.vc-container .vc-day.is-today .vc-highlights .vc-highlight {
    background-color: transparent !important;
    border-radius: 5px !important;
    border: 1px solid #ef8451 !important;
}

.vc-container .vc-day.is-today .vc-highlights+span {
    font-weight: initial;
    color: #ef8451 !important;
}

.vc-container .vc-day.is-today .vc-highlights+span:hover,
.vc-container .vc-day.is-today .vc-highlights+span:focus {
    background-color: transparent !important;
}

.vc-container .vc-svg-icon {
    color: #0f393b !important;
    opacity: 0.5 !important;
}

.vc-container .vc-weekday {
    font-family: Goldplay;
    font-size: 14px;
    color: #0f393b;
    opacity: 0.5;
}

.subtask-select-item {
    display: flex;
    gap: 10px;
}

.subtask-select-item img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    object-fit: cover;
}

.subtask-select-item div {
    color: #0f393b;
}

.subtask-select-item div.email {
    opacity: 0.2;
}

.el-select-dropdown__item {
    padding: 0 10px;
    box-sizing: border-box;
}

.el-select-dropdown__item.selected,
.el-select-dropdown__item.hover {
    background-color: #f8f7f4 !important;
}

.subtask-popper,
.users-popper {
    border-radius: 10px;
    min-width: 450px !important;
    background-color: #f5f3ee !important;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
}

.subtask-popper i,
.users-popper i {
    font-size: 24px;
    color: gray;
    margin: 0px 3px;
}

.user-select-item {
    display: flex;
    align-items: center;
    line-height: initial !important;
    padding: 7px 20px;
    font-size: 14px !important;
}

.user-select-item:hover,
.user-select-item.active {
    background-color: #f8f7f4 !important;
    background-color: #0f393b !important;
}

.user-select-item:hover div,
.user-select-item.active div {
    color: white !important;
}

.subtask-popup {
    display: flex;
    gap: 10px;
    align-items: center;
}

.subtask-popup .user-select-item {
    font-size: 13px;
}

.el-popover {
    width: fit-content !important;
    border-radius: 10px;
    border: 1.5px solid #ced9d2;
    /* fdsfs */
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
}

.project-dropdown .el-dropdown-link {
    cursor: pointer;
    font-size: 24px;
    color: #0f393b;
    border: none !important;
    outline: none !important;
}

.project-dropdown .el-dropdown-link i {
    font-size: 16px;
}

.project-dropdown-menu {
    min-width: 400px;
    border-radius: 10px !important;
    background: #f5f3ee !important;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
    padding: 0 !important;
}

.project-dropdown-menu .dropdown-item {
    padding: 0px 20px !important;
    font-size: 20px;
}

.project-dropdown-menu .dropdown-item .item-content {
    width: 100%;
    border-bottom: 1px solid #ced9d2;
    padding: 15px 0;
    font-size: 14px;
    display: flex;
    align-items: center;
    width: 100%;
    color: #0f393b;
    justify-content: space-between;
}

.project-dropdown-menu .dropdown-item.create-new .item-content {
    opacity: 0.5;
    border-bottom: none;
    font-size: 15px;
}

.project-dropdown-menu .dropdown-item.active {
    background-color: #ced9d2;
}

.project-dropdown-menu .dropdown-item.active .item-content {
    justify-content: space-between;
}

.project-dropdown-menu .dropdown-item:hover {
    background-color: #ced9d2 !important;
}

.project-dialog {
    background: #f5f3ee !important;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05) !important;
    border-radius: 10px !important;
    width: fit-content !important;
    padding: 30px;
}

.project-dialog .el-dialog__header {
    border-bottom: 2px solid #ced9d2;
    margin-bottom: 30px;
    font-size: 18px;
    padding-bottom: 10px;
}

.project-dialog .el-dialog__body,
.project-dialog .el-dialog__footer,
.project-dialog .el-dialog__header {
    padding: 0px !important;
}

.project-dialog .el-dialog__body {
    padding-bottom: 30px !important;
}

.project-dialog input {
    background-color: transparent;
    padding: 13px 20px;
    color: #0f393b;
    font-size: 18px;
    border: 1px solid #ced9d2;
    outline: none;
    border-radius: 10px;
}

.project-dialog .btn-save {
    color: white;
    background: #347362;
    border-radius: 10px;
    padding: 13px 30px;
    cursor: pointer;
}

.project-dialog .btn-cancel {
    color: #347362;
    font-size: 18px;
    cursor: pointer;
}

.project-dialog .footer-wrapper {
    padding-top: 2rem;
    display: flex;
    justify-content: flex-end;
}

.project-dialog .footer-wrapper .dialog-footer {
    display: flex;
    gap: 20px;
    align-items: center;
}

.el-input {
    height: fit-content;
}

.deps-menu {
    background-color: #f5f3ee !important;
}

.deps-menu .el-dropdown-menu__item.active,
.deps-menu .el-dropdown-menu__item:hover {
    background-color: #ced9d2 !important;
    color: #0f393b !important;
}