.users-wrapper {
   background-color: #eeebe3;
   display: grid;
   grid-template-rows: min-content auto;
}
.users-wrapper .btn-wrapper {
   height: calc(56px + 24px);
   display: flex;
   gap: 1.125rem;
   justify-content: center;
   padding-left: 1.25rem;
   padding-right: 0rem;
   align-items: center;
}
.users-wrapper .btn-wrapper .btn {
   padding: 0.5rem 0.5rem !important;
   font-size: 0.7rem !important;
   border: 1px solid #347362;
   font-family: inherit !important;
   line-height: inherit !important;
   border-radius: 10px;
   color: #0f393b;
   cursor: pointer;
   height: fit-content;
   transition: all 0.2s ease-in-out;
}
.users-wrapper .btn-wrapper .btn:hover {
   color: white;
   background-color: #347362;
}
.users-wrapper .users {
   display: grid;
   align-items: center;
   grid-auto-rows: 55px;
   border-top: 1px solid #ced9d2;
   border-right: 1px solid #ced9d2;
  /* height: 100%;
   */
   padding-top: 1px;
}
.users-wrapper .user {
   height: 100%;
   border-bottom: 1px solid #ced9d2;
}
.users-wrapper .user:last-child {
   border-bottom: none;
}
.users-wrapper .user .user-body {
   display: flex;
   align-items: center;
   height: 55px;
   gap: 10px;
   padding: 1.25rem 0.3rem;
   font-size: 15px;
}
.users-wrapper .user div {
   font-family: Goldplay;
   font-style: normal;
   font-weight: 500;
   font-size: 13px;
   line-height: 100%;
   color: #0f393b;
}
.users-wrapper .user img {
   height: 30px;
   aspect-ratio: 1;
   object-fit: cover;
   border-radius: 100%;
}