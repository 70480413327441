.sub-header {
   display: flex;
   padding: 40px 0;
   margin: 0 60px;
   height: 45px;
   box-sizing: content-box;
   align-items: center;
   margin-bottom: 10px;
   padding-bottom: 20px;
   border-bottom: 1px solid #ced9d2;
   justify-content: space-between;
}
.sub-header .right {
   display: flex;
   gap: 30px;
}
.sub-header .right .imgs {
   border-right: 1px solid #ced9d2;
   padding-right: 14px;
   display: flex;
}
.sub-header .right .imgs img {
   border-radius: 50%;
   border: 2px solid #eeebe3;
   height: 35px;
   width: 35px;
   object-fit: cover;
   position: relative;
   margin-left: -7px;
}
.sub-header .btn-cont {
   display: flex;
   gap: 1.125rem;
   justify-content: center;
   padding-left: 1.25rem;
}
.sub-header .btn-cont .btn {
   padding: 0.75rem 0.5rem;
   border: 1px solid #347362;
   border-radius: 10px;
   color: #0f393b;
   font-size: 1rem;
   cursor: pointer;
}
.sub-header .dropdown-link {
   font-size: 24px;
   display: flex;
   justify-content: center;
   align-items: center;
}
.sub-header .search-input {
   background-color: transparent !important;
   color: #0f393b 81 !important;
   border: 1px solid #0f393b 81 !important;
   border-radius: 10px !important;
   outline: none;
   padding: 5px 10px;
}
.sub-header .search-input input {
   padding-left: 10px;
}