.task-details-wrapper {
   overflow: auto;
   display: flex;
   width: 0;
   flex-direction: column;
   height: 100%;
   background: #f5f3ee;
  /* SEO Suite/Pale Green */
   box-sizing: border-box;
   border-radius: 0px;
   position: fixed;
   right: 0;
   top: 0;
   z-index: 100;
   transition: width 0.25s ease-in;
   overflow: hidden;
}
.task-details-wrapper .files {
   display: flex;
   flex-direction: column;
   gap: 10px;
   padding: 0px 10px;
}
.task-details-wrapper .files .file {
   display: flex;
   justify-content: space-between;
   transition: all 0.2s ease-in-out;
}
.task-details-wrapper .files .file a {
   text-decoration: none;
   color: #347362;
}
.task-details-wrapper .files .file:hover {
   background-color: #347362;
}
.task-details-wrapper .files .file:hover a {
   color: white;
}
.task-details-wrapper .files .file:hover i {
   color: white;
}
.task-details-wrapper.opened {
   width: 50%;
   border: 1px solid #ced9d2;
   box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
   padding: 80px 30px 30px 30px;
   overflow: auto;
}
.task-details-wrapper .header {
   display: flex;
   align-items: flex-start;
   justify-content: space-between;
   padding-bottom: 10px;
   border-bottom: 1px solid #ced9d2;
}
.task-details-wrapper .header .btn {
   display: flex;
   align-items: center;
   gap: 0.3rem;
}
.task-details-wrapper .header .btn.completed {
   background-color: #347362;
   color: white;
}
.task-details-wrapper .header .btn.completed path {
   stroke: white;
}
.task-details-wrapper .header .icons {
   display: flex;
   cursor: pointer;
   gap: 1.3rem;
}
.task-details-wrapper .header .icons svg {
   opacity: 0.5;
}
.task-details-wrapper .body {
   display: flex;
   padding-top: 30px;
   flex-direction: column;
}
.task-details-wrapper .body .title {
   font-style: normal;
   font-size: 22px;
   line-height: 100%;
   color: #0f393b;
   margin-bottom: 10px;
}
.task-details-wrapper .body .desc-label {
   margin-top: 30px;
   margin-bottom: 10px;
   color: #0f393b;
}
.task-details-wrapper .body .desc {
   width: 100%;
   border: none;
   outline: none;
   background-color: transparent;
   font-size: 13px;
   color: #0f393b;
   opacity: 0.5;
}
.task-details-wrapper .body .details-table {
   display: grid;
   width: 100%;
   grid-template-columns: 1fr 2fr;
   grid-auto-rows: 35px;
   align-items: center;
}
.task-details-wrapper .body .details-table .details-label {
   font-family: Goldplay;
   font-size: 13px;
   color: #0f393b;
   opacity: 0.5;
}
.task-details-wrapper .body .details-table .details-label + div {
   font-size: 15px;
   color: #0f393b;
}
.task-details-wrapper .body .details-table .date-wrapper {
   display: flex;
   align-items: center;
   font-size: 18px;
}
.task-details-wrapper .body .details-table .date-wrapper svg {
   margin-right: 10px;
}
.task-details-wrapper .body .details-table .assignee {
   display: flex;
   align-items: center;
   gap: 5px;
}
.task-details-wrapper .body .details-table .assignee img {
   height: 25px;
   width: 25px;
   border-radius: 50%;
   object-fit: cover;
}
.task-details-wrapper .body .details-table .assignee div {
   font-family: Goldplay;
   font-size: 13px;
   color: #0f393b;
}
.task-details-wrapper .body .details-table .add-deps span {
   cursor: pointer;
   font-size: 13px;
   color: #0f393b;
}
.task-details-wrapper .body .details-table .empty-priority {
   width: 1rem;
   border-bottom: 1.5px solid #000;
   opacity: 0.45;
}
.subtasks-wrapper {
   margin-top: 50px;
}
.subtasks-wrapper .btn {
   width: fit-content;
   margin-top: 10px;
}
.subtasks-wrapper .subtasks {
   display: flex;
   flex-direction: column;
   border-top: 1px solid #ced9d2;
}
.subtasks-wrapper .subtasks .subtask {
   position: relative;
   cursor: pointer;
   display: flex;
   justify-content: space-between;
   width: 100%;
   border-bottom: 1px solid #ced9d2;
   padding: 10px;
   align-items: center;
}
.subtasks-wrapper .subtasks .subtask .subtask-input {
   background-color: transparent;
   outline: none;
   border: 1px solid darkgreen;
   flex-grow: 1;
   width: 100%;
   font-size: 13px;
   color: #0f393b;
}
.subtasks-wrapper .subtasks .subtask.completed .left input {
   color: #0f393b 44;
   opacity: 0.75 !important;
}
.subtasks-wrapper .subtasks .subtask .right {
   gap: 1rem;
   display: grid;
   grid-template-columns: 150px 30px;
   justify-content: end;
   row-gap: 10px;
}
.subtasks-wrapper .subtasks .subtask .right + span + div {
   justify-content: flex-end;
}
.subtasks-wrapper .subtasks .subtask .right img {
   height: 24px;
   width: 24px;
   object-fit: cover;
   border-radius: 50%;
}
.subtasks-wrapper .subtasks .subtask.selected {
   background: #ced9d2;
   border-radius: 5px;
}
.subtasks-wrapper .subtasks .subtask .left {
   display: flex;
   align-items: center;
   gap: 10px;
   flex-grow: 1;
   padding-right: 10px;
}
.subtasks-wrapper .subtasks .subtask i {
   opacity: 0;
   position: absolute;
   left: 0;
   transform: translate(-110%, 0);
}
.subtasks-wrapper .subtasks .subtask:hover i {
   opacity: 1;
}
.subtasks-wrapper .subtasks .subtask:hover i:hover {
   color: #ef8451;
}
.add-deps-heading {
   font-family: Goldplay-Bold;
   cursor: pointer;
   font-size: 13px;
   color: #0f393b;
}
.deps-menu-wrapper .MuiPaper-root {
   background-color: #f5f3ee !important;
}
.deps-menu-wrapper .MuiMenuItem-root {
   font-size: 14px !important;
}
.deps-menu-wrapper .MuiMenuItem-root.active, .deps-menu-wrapper .MuiMenuItem-root:hover {
   background-color: #ced9d2 !important;
   color: #0f393b !important;
}
.subtask-popup {
   padding: 0.5rem 0;
}
.subtask-popup .MuiOutlinedInput-input {
   background-color: transparent !important;
   color: #0f393b 81 !important;
   border: 1px solid #0f393b 81 !important;
   border-radius: 10px !important;
   outline: none;
   font-size: 14px !important;
}
.subtask-popup .MuiOutlinedInput-notchedOutline {
   border: none !important;
}
.subtask-popup .MuiSvgIcon-root {
   display: none !important;
}
.test.test {
   background-color: #000 !important;
}