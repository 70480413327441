.wrapper-wrapper {
   display: grid;
   grid-template-columns: 1fr 4fr;
}
.wrapper-wrapper .wrapper {
   position: relative;
}
.wrapper-wrapper .wrapper .calendar-labels-grid {
   user-select: none;
   -webkit-user-drag: none;
   display: grid;
   grid-auto-flow: column;
   grid-auto-columns: 38px;
   place-items: center;
   background-color: #eeebe3;
   height: 25px;
   border-bottom: 1px solid #ced9d2;
}
.wrapper-wrapper .wrapper .calendar-labels-grid div {
   font-weight: 500;
   font-size: 1rem;
   height: 100%;
   width: 100%;
   text-align: center;
   color: #0f393b;
}
.wrapper-wrapper .wrapper .calendar-labels-grid div.weekend {
   background: #efece6;
   border: 1px solid #ced9d2;
   border-bottom: none;
   color: #0f393b;
   opacity: 0.5;
}
.wrapper-wrapper .wrapper .calendar-labels-grid div.weekend.sunday {
   border-left: none;
   border-radius: 0px 5px 0px 0px;
}
.wrapper-wrapper .wrapper .calendar-labels-grid div.weekend.saturday {
   border-right: none;
   border-radius: 5px 0px 0px 0px;
}
.wrapper-wrapper .wrapper .calendar-labels-grid div.highlighted {
   background: #ef8451;
   border: none;
   border-radius: 0 !important;
   opacity: 1;
   color: white;
}
.wrapper-wrapper .wrapper .calendar-labels-grid div.highlighted.highlightedStart {
   border-radius: 5px 0px 0px 0px !important;
}
.wrapper-wrapper .wrapper .calendar-labels-grid div.highlighted.highlightedEnd {
   border-radius: 0px 5px 0px 0px !important;
}
.wrapper {
   height: 100%;
   width: 100%;
   overflow: auto;
}
#grid-grid {
   display: grid;
   grid-template-rows: min-content min-content auto;
   height: 100%;
}
#grid-grid .month-labels {
   user-select: none;
   -webkit-user-drag: none;
   position: relative;
   display: grid;
   grid-auto-flow: column;
   grid-auto-columns: 38px;
   background-color: #eeebe3;
   height: 56px;
}
#grid-grid .month-labels + div:not(.filters) {
   border-left: 2px solid #ced9d2;
   padding-left: 0.5rem;
   font-size: 1.125rem;
   color: #0f393b;
   opacity: 0.5;
   height: fit-content;
}
#grid-grid #grid {
   display: grid;
   height: 100%;
   background: #f5f3ee;
   grid-auto-flow: dense;
   grid-auto-rows: 55px;
   grid-auto-columns: 38px;
}
#grid-grid #grid .user-divider {
   border-bottom: 1px solid #ced9d2;
   width: 100%;
   height: 0px;
}
#grid-grid #grid .weekend-overlay {
   width: 100%;
  /* position: absolute;
   */
   background-color: #efece6;
}
.task-wrapper {
  /* input {
      border: none;
      outline: none;
      background: transparent;
      color: #0f393b;
      font-family: &#34;
     Goldplay&#34;
     ;
      font-size: 16px;
  }
   */
   display: flex;
  /* display: grid;
   grid-template-columns: 12px auto 12px;
   */
   background: #eeebe3;
   -webkit-user-drag: none;
  /* SEO Suite/Pale Green */
   margin: 5px 0;
   box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
   position: relative;
  /* overflow: hidden;
   */
   border: 1px solid #ced9d2;
  /* border-radius: 10px;
   */
   cursor: pointer;
   border-radius: 10px;
}
.task-wrapper .close-icon {
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: #0f393b;
   opacity: 0.25;
   position: absolute;
   top: 0;
   left: 100%;
   color: white;
   padding: 0.15rem;
   border-radius: 50%;
   font-size: 12px;
   transform: translate(-60%, -40%);
   transition: all 0.2s ease-in-out;
   z-index: 10;
   width: 18px;
   aspect-ratio: 1;
}
.task-wrapper .close-icon:hover {
   opacity: 1;
}
.task-wrapper.completed:not(.selected) {
   opacity: 0.3;
}
.task-wrapper .task-tooltip {
   display: none;
   position: absolute;
   top: -10px;
   left: 50%;
   transform: translate(0%, -100%);
   min-width: fit-content;
   z-index: 1;
}
.task-wrapper .task-tooltip div, .task-wrapper .task-tooltip .complete-icon {
   display: block !important;
}
.task-wrapper .task-tooltip .subtasks-count {
   display: flex !important;
}
.task-wrapper .task-tooltip .task-body {
   display: flex !important;
   border: none !important;
}
.task-wrapper.highlighted {
   border: 1px solid #ef8451;
}
.task-wrapper .task-body {
   flex-grow: 1;
   user-select: none;
   padding: 5px;
   display: flex;
   align-items: center;
   gap: 5px;
}
.task-wrapper .task-body .complete-icon {
   opacity: 0.5;
}
.task-wrapper .task-body div {
   font-size: 13px;
   font-family: Goldplay;
   padding: 0.2rem 0.3rem;
}
.task-wrapper .task-body img {
   height: 30px;
   width: 30px;
   border-radius: 50%;
   object-fit: cover;
}
.task-wrapper .task-body .subtasks-count {
   margin-left: 10px;
   font-size: 18px;
   opacity: 0.5;
   display: flex;
   align-items: center;
   gap: 10px;
}
.task-wrapper.small .task-body {
   flex-grow: 1;
}
.task-wrapper.small .task-body div, .task-wrapper.small .task-body .complete-icon, .task-wrapper.small .task-body .subtasks-count {
   display: none;
}
.task-wrapper.verySmall .task-mover {
   width: 6px;
}
.task-wrapper.verySmall .task-body {
   padding: 2px;
}
.task-wrapper.verySmall .task-body div, .task-wrapper.verySmall .task-body .complete-icon, .task-wrapper.verySmall .task-body .subtasks-count {
   display: none;
}
.task-wrapper.verySmall .task-body img {
   width: 100%;
   aspect-ratio: 1;
}
.task-wrapper.show-tooltip:hover .task-tooltip {
   display: flex;
   z-index: 100;
}
.task-wrapper.show-tooltip:hover .task-tooltip .task-content {
   display: block;
}
.task-wrapper.selected {
   border-radius: 0 !important;
   border: none;
   cursor: grab;
   z-index: 100;
}
.task-wrapper.selected .task-body {
   border: 1px solid #ef8451;
}
.task-mover {
   background: #ef8451;
  /* border-radius: 6px 0px 0px 6px;
   */
   display: flex;
   align-items: center;
   justify-content: center;
   width: 12px;
   height: 100%;
   position: relative;
   cursor: e-resize;
  /* &amp;
  ::after, &amp;
  ::before {
      content: &#34;
     &#34;
     ;
      position: absolute;
      height: 17px;
      min-width: 1px;
      max-width: 1px;
      background-color: white;
      top: 50%;
      transform: translate(-50%, -50%);
  }
   */
}
.task-mover .line {
   position: absolute;
   height: 17px;
   min-width: 1px;
   max-width: 1px;
   width: 1px;
   background-color: white;
   top: 50%;
   transform: translate(-50%, -50%);
}
.task-mover .line:first-child {
   left: 70%;
}
.task-mover .line:last-child {
   left: 30%;
}
.task-mover.task-mover-left {
   border-radius: 10px 0px 0px 10px;
   -webkit-border-radius: 10px 0px 0px 10px;
}
.task-mover.task-mover-right {
   border-radius: 0px 10px 10px 0px;
   -webkit-border-radius: 0px 10px 10px 0px;
}
.task-mover.task-mover-right svg {
   transform: translateX(-1px);
}
.main-svg {
   background-color: transparent;
   position: absolute;
   height: calc(100% - 56px - 25px);
   width: 100%;
   top: calc(56px + 25px);
}
.main-svg g.close {
   opacity: 0;
   transition: all 0.25s ease-in-out;
   cursor: pointer;
}
.main-svg g.close:hover {
   opacity: 1;
}
.main-svg .invisible-path:hover + g.close {
   opacity: 0.5;
}
.main-svg path {
   stroke: #7e7e7e;
}
.main-svg path.highlighted {
   stroke: #ef8451;
}
.main-svg polygon {
   fill: #7e7e7e;
   stroke: #7e7e7e;
   stroke-width: 1px;
   cursor: pointer;
}
.main-svg polygon.highlighted {
   stroke: #ef8451;
   fill: #ef8451;
}
.main-svg .cross-line {
   stroke: #f5f3ee;
   stroke-width: 2;
}
.main-svg .movement-circle {
   fill-opacity: 0;
   stroke-width: 1px;
   fill: #7e7e7e;
   cursor: pointer;
  /* &amp;
  :hover {
      fill-opacity: 1;
  }
   */
   transition: all 0.2s ease-in-out;
}