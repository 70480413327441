html,
body,
#app {
   height: 100%;
}
#sub-app {
   height: 90%;
}

/* .btn {
   padding: 0.75rem 0.5rem;
   font-size: 0.9rem;
   border: 1px solid #347362;
   border-radius: 10px;
   color: #0f393b;
   cursor: pointer;
   height: fit-content;
} */
.light-text {
   color: #0f393b;
   opacity: 0.5;
}
.popper__arrow::after {
   border-top-color: #f5f3ee !important;
   border-bottom-color: #f5f3ee !important;
}
.el-popover.el-popper {
   background: #f5f3ee;
}
.el-input__inner {
   background-color: transparent !important;
   color: #0f393b81 !important;
   border: 1px solid #0f393b81 !important;
   border-radius: 10px !important;
   outline: none;
}
.MuiList-padding{

   padding: 0 !important;
}
.MuiCalendarPicker-root{
   background-color: #f5f3ee;
   border: 1px solid rgb(203, 213, 224);
   box-shadow: none !important;
}
.MuiPickersDay-root{
   background-color: transparent !important;
   font-size: 14px !important;
   font-family: "Goldplay-Medium" !important;
   font-weight: 500;
   color:rgb(15, 57, 59) !important;

}

.MuiPickersDay-root.Mui-selected{
   font-family: "Goldplay-Bold" !important;
   background: #e5e8e9 !important;

}
.MuiPickersDay-root.MuiPickersDay-today{
   border-radius: 5px !important;
   border-color: #ef8451 !important;
   border-width: 1px !important;
   border-style: solid !important;
   font-family: "Goldplay-Bold" !important;
   color: #ef8451 !important;
   background-color: transparent !important;

}